<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="850"
  >
    <v-card :loading="loading">
      <v-card-title class="popup-header">
        <v-layout class="fill-height" align-center>
          <span>
            Image Info
          </span>
        </v-layout>
        <v-spacer/>
        <v-btn icon small dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-5">
        <div style="width: 100%" class="d-flex">
          <div class="pr-4" style="width: 450px">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr>
                  <td style="font-weight: bold">Catalog ID</td>
                  <td>{{ currentImage.id }}</td>
                </tr>
                <tr>
                  <td style="font-weight: bold">Type</td>
                  <td style="width: 380px">{{ currentImage.type }}</td>
                </tr>
                <tr>
                  <td style="font-weight: bold">Date</td>
                  <td>{{ currentImage.date }}</td>
                </tr>
                <tr>
                  <td style="font-weight: bold">Resolution</td>
                  <td>{{ currentImage.resolution }}</td>
                </tr>
                <tr>
                  <td style="font-weight: bold">Cloud cover</td>
                  <td>{{ currentImage.cloud_cover }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <v-layout align-center justify-center class="pa-2"
                    style="min-height: 250px; border: 1px solid lightgrey; border-radius: 5px">
            <img v-if="currentImage.url" :src="currentImage.url" alt="thumbnail" width="300"/>
          </v-layout>
        </div>
      </v-card-text>
      <v-card-actions>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {getThumbnail} from '@/backend'
import config from '@/config.json'
export default {
  name: "ImageInfo",
  data() {
    return {
      dialog: false,
      loading: false,
      currentImage: {}
    }
  },
  methods: {
    openDialog(currentImage) {
      this.currentImage = currentImage
      this.getLinkThumbnail(currentImage)
      this.dialog = true
    },
    async getLinkThumbnail(currentImage) {
      try {
        this.loading = true
        const res = await getThumbnail({
          dataset: currentImage.dataset,
          thumbnail: currentImage.thumbnail
        })
        this.currentImage['url'] = (res.data.url.includes("http") ? "" : `${config.backend_discovery_IG}`) + res.data.url
        console.log(this.currentImage)
      } catch (e) {
        console.log(e.message)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
