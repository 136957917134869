var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"height":"100%","flat":""}},[_c('v-data-table',{staticClass:"row-pointer order-table",staticStyle:{"height":"77%","border":"1px lightgrey solid"},attrs:{"height":"100%","fixed-header":"","hide-default-footer":"","headers":_vm.orderHeaders,"items":_vm.orders,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id","show-expand":"","loading":_vm.loading,"items-per-page":15,"loading-text":"Loading... Please wait","server-items-length":_vm.totalItem,"options":_vm.options,"page":_vm.page},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event},"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-layout',{staticClass:"py-5",attrs:{"align-center":"","justify-center":"","column":""}},[_c('img',{attrs:{"src":require('@/assets/images/orders-icon-26.jpg'),"width":"100"}}),_c('span',{staticClass:"pl-4 pt-3",staticStyle:{"font-size":"16px","color":"#777c85"}},[_vm._v("No data order")])])]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleDateString("en-GB"))+" ")]}},{key:"item.image_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.images.length)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",staticStyle:{"width":"180px"},attrs:{"small":"","color":_vm.statuses.find(function (status) { return status.id == item.status; }).color,"text-color":"white"}},[_c('v-layout',{staticClass:"fill-height",staticStyle:{"width":"100%"},attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.statuses.find(function (status) { return status.id == item.status; }).value)+" ")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-0 list-image",attrs:{"headers":_vm.orderItemHeaders,"items":item.images,"item-key":"id","hide-default-footer":"","disable-filtering":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!item.link || item.status != 6,"icon":"","color":"success","title":"Download"},on:{"click":function($event){return _vm.download(item.id)}}},[_c('v-icon',[_vm._v("mdi-download-outline")])],1),_c('v-btn',{attrs:{"disabled":!item.link || item.status != 6,"icon":"","color":"primary","title":"Import to Eofactory"},on:{"click":function($event){return _vm.importToEOF(item)}}},[_c('v-icon',[_vm._v("mdi-import")])],1)]}}],null,true)})],1)]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{staticClass:"ma-3 mt-6",attrs:{"total-visible":5,"length":_vm.pageCount,"id":"task_pagination"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),(_vm.currentUser)?_c('WorkspaceDialog',{ref:"workspaceDialog"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }