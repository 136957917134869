<template>
  <div
    class="d-flex column fill-height flex-column py-2 px-3"
    style="flex: 1; border-right: 1px solid lightgray"
  >
    <v-form ref="formData" v-model="isFormDataValid" class="fill-height">
      <v-stepper v-model="e6" vertical flat>
        <v-stepper-step :complete="e6 > 1" step="1" editable class="pl-0">
          Name
        </v-stepper-step>

        <v-stepper-content step="1" style="margin-left: 11px">
          <v-form ref="orderName" v-model="isOrderNameValid">
            <v-text-field
              filled
              dense
              v-model="orderName"
              :rules="[rules.required]"
              label="Order name"
              background-color="#F1F9FF"
            ></v-text-field>
          </v-form>
          <v-btn
            color="primary"
            @click="
              () => {
                $refs.orderName.validate();
                if (!isOrderNameValid) return;
                e6 = 2;
              }
            "
          >
            Continue
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 2" step="2" editable class="pl-0">
          Select delivery destination
        </v-stepper-step>

        <v-stepper-content step="2" style="margin-left: 11px">
          <div class="d-flex">
            <v-checkbox
              v-model="isAmazonS3Selected"
              label="Amazon S3"
              class="mt-0"
              @click="openAmazonS3ConfigDialog"
            ></v-checkbox>
            <v-btn
              icon
              small
              class="ml-auto"
              @click="openAmazonS3ConfigDialog"
              :disabled="!isAmazonS3Selected"
            >
              <v-icon small>mdi-cogs</v-icon>
            </v-btn>
          </div>
          <!-- <div class="d-flex">
            <v-checkbox
              v-model="isGoogleCloudStorageSelected"
              label="Google Cloud Storage"
              value="google_cloud_storage"
              class="mt-0"
              disabled
            ></v-checkbox>
            <v-btn
              icon
              small
              class="ml-auto"
              @click="openAmazonS3ConfigDialog"
              :disabled="true"
            >
              <v-icon small>mdi-cogs</v-icon>
            </v-btn>
          </div> -->
          <v-btn color="primary" @click="e6 = 3"> Continue </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 3" step="3" class="pl-0">
          Tool
        </v-stepper-step>

        <v-stepper-content step="3" style="margin-left: 11px">
          <v-radio-group v-model="isClip" row class="mt-0">
            <v-radio label="Sence" :value="false"></v-radio>
            <!-- <v-radio label="Clip" :value="true"></v-radio> -->
          </v-radio-group>
        </v-stepper-content>
      </v-stepper>
    </v-form>
    <span class="text-uppercase">Selected: {{ listImage.length }}</span>
    <amazon-s-3-config-dialog
      ref="amazonS3ConfigRef"
      @uncheckAmazonS3Config="uncheckAmazonS3Config"
    ></amazon-s-3-config-dialog>
  </div>
</template>

<script>
import { latconnect60Order } from "@/backend";
import AmazonS3ConfigDialog from "./AmazonS3ConfigDialog.vue";
export default {
  components: { AmazonS3ConfigDialog },
  name: "QuotationLatconnect60",
  props: {
    listImage: { type: Array, default: () => [] },
    search: { type: Object, default: () => {} },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      rules: {
        required: (value) => !!value || "Field is required",
      },
      orderName: "",
      isClip: false,
      e6: 1,
      isOrderNameValid: false,
      isAmazonS3Selected: false,
      isGoogleCloudStorageSelected: false,
      isFormDataValid: false
    };
  },
  methods: {
    openAmazonS3ConfigDialog() {
      if (this.isAmazonS3Selected) this.$refs.amazonS3ConfigRef.openDialog();
    },
    uncheckAmazonS3Config() {
      this.isAmazonS3Selected = false;
    },
    async submit() {
      this.$refs.formData.validate();
      if (!this.isFormDataValid) return;
      try {
        this.loading = true;
        const bodyOrder = {
          name: this.orderName,
          delivery: {},
          tools: [],
          products: this.listImage.map(el => {
            return {
              'id': el.product_id,
              'path': el.path
            }
          })
        }
        if(this.isAmazonS3Selected) {
          bodyOrder.delivery.amazon_s3 = {
            'bucket': this.$refs.amazonS3ConfigRef.amazonS3Config.bucket,
            'aws_region': this.$refs.amazonS3ConfigRef.amazonS3Config.region,
            'aws_access_key_id': this.$refs.amazonS3ConfigRef.amazonS3Config.accessKey,
            'aws_secret_access_key': this.$refs.amazonS3ConfigRef.amazonS3Config.secretKey,
            'path_prefix': this.$refs.amazonS3ConfigRef.amazonS3Config.bucketPathPrefix
          }
        }
        if(this.isClip) {
          bodyOrder.tools.push({
            'clip': {
              'aoi': this.search.geojson
            }
          })
        }
        const res = await latconnect60Order(bodyOrder);
        this.$store.commit("message/SHOW_SUCCESS", res.data.message);
      } catch (e) {
        this.$store.commit("message/SHOW_ERROR", e.message);
      } finally {
        this.loading = false;
        this.dialog = false;
      }
    },
  },
};
</script>