<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="400"
  >
    <v-card>
      <v-card-title class="popup-header">
        <span>Create</span>
        <v-spacer/>
        <v-btn icon small dark @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="pa-5">
        <v-form v-model="valid" ref="formData">
          <v-text-field
              v-model="name"
              :rules="[rules.required]"
              filled
              dense
              background-color="#F1F9FF"
              label="Name">
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn :loading="loading" text color="accent" @click="submitCreate">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AOI from "@/ultis/AOI";
import {mapState} from "@/store/ults";

export default {
  name: "CreateDialog",
  props: {
    action: {
    }
  },
  data () {
    return {
      dialog: false,
      rules: {
        required: value => !!value || 'Name is required',
      },
      name: '',
      id: '',
      loading: false,
      valid: false
    }
  },
  computed: {
    ...mapState("aoi", ["currentAOI"])
  },
  methods: {
    openDialog () {
      this.dialog = true
    },
    async submitCreate () {
      this.$refs.formData.validate()
      if (!this.valid) return
      try {
        this.loading = true
        const res = await AOI.saveAOI(
           this.name,
            this.currentAOI
        )
        this.$emit('fetchData')
        this.$store.commit('message/SHOW_SUCCESS', res.data.message)
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        this.loading = false
        this.dialog = false
      }
    }
  }
}
</script>

<style scoped>

</style>
