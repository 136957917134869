<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="400"
  >
    <v-card>
      <v-card-title class="popup-header">
        <span>Delete</span>
        <v-spacer/>
        <v-btn icon small dark @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="pa-5">
        <p>Are you sure you want to delete item: <b>{{name}}</b></p>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn :loading="loading" text color="accent" @click="submitDelete">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteDialog",
  props: {
    action: {
    }
  },
  data () {
    return {
      dialog: false,
      id: '',
      name: '',
      loading: false
    }
  },
  methods: {
    openDialog (name, id) {
      this.id = id
      this.name = JSON.parse(JSON.stringify(name))
      this.dialog = true
    },
    async submitDelete () {
      try {
        this.loading = true
        const res = await this.action({id: this.id})
        this.$emit('fetchData')
        this.$store.commit('message/SHOW_SUCCESS', res.data.message)
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        this.loading = false
        this.dialog = false
      }
    }
  }
}
</script>

<style scoped>

</style>
