<template>
  <v-dialog v-if="saveSearchData" color="#003F71" v-model="isShowDialog" persistent max-width="400px">
    <v-card>
      <v-card-title class="popup-header">
        <span>Save search</span>
        <v-spacer></v-spacer>
        <v-btn dark icon small @click="closeDialog"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text class="pa-5">
        <v-container>
          <v-row>
            <v-text-field
              label="Enter name"
              background-color="#F1F9FF"
              v-model="name"
              filled
              dense
              class="px-4"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item
                  style="border-bottom: 1px solid lightgray"
                  disabled
                >
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >Start:
                      {{ saveSearchData.filter.start }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      >End:
                      {{ saveSearchData.filter.end }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      >Resolution: {{ saveSearchData.filter.resolution[0] }} m -
                      {{ saveSearchData.filter.resolution[1] }}
                      m</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      >Cloud coverage:
                      {{
                        saveSearchData.filter.cloud_cover
                      }}%</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      >Dataset: {{ datasetLength }}/18</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-layout justify-center>
          <v-btn
            color="primary"
            :disabled="!name"
            :loading="isLoading"
            @click="saveCurrentSearch"
            >SAVE</v-btn
          >
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createSavedSearch } from "@/backend";
import { mapState } from '@/store/ults'

export default {
  props: {
    searchs: { type: Array, required: true },
  },

  data() {
    return {
      isShowDialog: false,
      isLoading: false,
      name: undefined,
      saveSearchData: undefined,
    };
  },

  computed: {
    ...mapState('aoi', ['currentAOI']),
    datasetLength() {
      return Object.values(this.saveSearchData.dataset).reduce(
        (total, currentValue) => total + currentValue.length,
        0
      );
    },
  },

  methods: {
    openSaveSearchDialog(data) {
      this.saveSearchData = data;
      this.isShowDialog = true;
    },
    async saveCurrentSearch() {
      try {
        this.isLoading = true;
        await createSavedSearch({
          name: this.name,
          payload: {
            dataset: this.saveSearchData.dataset,
            dataset_length: this.datasetLength,
            filter: this.saveSearchData.filter,
            geojson: this.currentAOI ? this.currentAOI.features[0] : null,
          },
        });
        this.$store.commit("message/SHOW_SUCCESS", "Saved Search Successfully");
        this.$emit("openSearchManagementDialog", this.saveSearchData);
      } catch (error) {
      } finally {
        this.name = undefined;
        this.isShowDialog = false;
        this.isLoading = false;
      }
    },
    closeDialog() {
      Object.assign(this.$data, this.$options.data());
    }
  },
};
</script>
