<template>
  <v-dialog color="#003F71" persistent max-width="450px" v-model="isShowDialog">
    <v-card>
      <v-card-title class="popup-header">
        <span>Workspaces</span>
        <v-spacer></v-spacer>
        <v-btn dark icon small @click="closeWorkspaceDialog"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text class="pa-5" style="max-height: 50vh; overflow-y:auto">
        <v-list>
          <v-list-item-group v-model="workspaceSelected">
            <v-list-item v-for="(item, i) in workspaces" :key="i">
              <v-list-item-icon>
                <v-icon>mdi-folder</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          color="primary"
          :disabled="workspaceSelected == undefined"
          @click="importImageToEOF"
          >Import</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { importImageToEOF, getListWorkspaces } from "@/backend";
export default {
  data() {
    return {
      loading: false,
      isShowDialog: false,
      workspaces: [],
      imageSelected: undefined,
      workspaceSelected: undefined,
    };
  },

  async created() {
    const { data } = await getListWorkspaces();
    this.workspaces = data.data;
  },

  methods: {
    openWorkspaceDialog(image) {
      this.imageSelected = image;
      this.isShowDialog = true;
    },
    async importImageToEOF() {
      try {
        this.loading = true;
        await importImageToEOF({
          workspace_id: this.workspaces[this.workspaceSelected].id,
          name: this.imageSelected.name,
          image_name: this.imageSelected.name,
          image_url: this.imageSelected.link,
          metadata: this.imageSelected,
        });
        this.$store.commit("message/SHOW_SUCCESS", "Imported to workspace");
      } catch (error) {
        this.$store.commit("message/SHOW_ERROR", error.response && error.response.data ? error.response.data.message : "An error has occurred");
      } finally {
        this.resetData();
      }
    },
    closeWorkspaceDialog() {
      this.resetData();
    },
    resetData() {
      this.isShowDialog = false;
      this.workspaceSelected = undefined;
      this.loading = false;
    },
  },
};
</script>
