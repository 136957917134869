<template>
  <div class="d-flex flex-column elevation-1"
       style="height: 100%; width: 100%; border-bottom-right-radius: 5px; border-top-right-radius: 5px; background-color: #fff">
    <v-overlay v-if="loading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <div>
      <div style="width: 100%; height: 45px;" class="accent px-4">
        <div class="d-flex align-center">
          <span style="line-height: 45px; color: #fff">Image Search</span>
          <v-spacer/>
          <v-btn icon small @click="() => $emit('closeControl')" dark>
            <v-icon>mdi-arrow-left-drop-circle-outline</v-icon>
          </v-btn>
        </div>
      </div>
      <div style="width: 100%; height: 45px;" class="secondary px-4">
        <v-layout align-center class="fill-height">
          <span style="line-height: 45px;">Filter</span>
          <v-spacer/>
          <v-btn icon small color="primary" @click="isDisplayFilterForm = !isDisplayFilterForm">
            <v-icon>{{ isDisplayFilterForm ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-layout>
      </div>
    </div>
    <div style="overflow-y: auto; min-height: 0" class="flex-fill py-2 px-3" v-show="isDisplayFilterForm">
      <div class="py-2">
        <geocoder
            class="flex-none base-map-search-bar"
            height="52px"
            background-color="#F1F9FF"
            color="#5B778F"
            style="padding: 0; margin: 0"
            placeholder="Search location on map"
            @change="flyToLocation"
        />
      </div>
      <div class="d-flex py-2">
        <v-btn :disabled="!currentUser" small outlined color="accent" height="38" width="38" min-width="0" class="mr-4"
               @click="openDataAlertDialog">
          <v-icon size="22">mdi-bell-ring-outline</v-icon>
        </v-btn>
        <v-btn small outlined color="accent" height="38" class="flex-fill" min-width="0" @click="openDatasetForm">
          <v-icon size="22" class="mr-2">mdi-clipboard-list-outline</v-icon>
          Select dataset
        </v-btn>
      </div>
      <div class="py-2">
        <DatePicker label="Start date" v-model="startDate" :max="endDate"/>
        <DatePicker label="End date" v-model="endDate" :min="startDate"/>
      </div>
      <div class="pb-1">
        <v-layout class="pb-1" align-center>
          <div style="width: 105px">
            <span style="font-size: 14px">Cloud Coverage:</span>
          </div>
          <v-layout>
            <v-slider
                dense
                hide-details
                v-model="cloudCover"
                step="1"
                min="0"
                max="90"
                thumb-label
                ticks
            ></v-slider>
          </v-layout>
        </v-layout>
        <v-layout class="pt-1" align-center>
          <div style="width: 105px">
            <span style="font-size: 14px">Resolution:</span>
          </div>
          <v-layout>
            <v-range-slider
                v-model="resolution"
                max="23"
                min="0"
                hide-details
                thumb-label
                class="align-center"
            >
            </v-range-slider>
          </v-layout>
        </v-layout>
      </div>
      <div class="py-2 d-flex">
        <v-btn outlined color="accent" class="flex-grow-1 mr-2" @click="resetFilter">Reset</v-btn>
        <v-btn color="accent" class="flex-grow-1 ml-2" @click="searchData">Search</v-btn>
      </div>
    </div>
    <div style="overflow-y: auto; min-height: 0" class="d-flex flex-fill flex-column" v-if="!isDisplayFilterForm">
      <div class="overflow-y-auto flex-fill">
        <div style="height: 45px; flex: none" class="pt-1 px-4 secondary" v-if="data.length">
          <v-layout align-center>
            <div style="font-size: 12px; height: 100%; width: 40px" class="px-1 d-flex align-center">Action</div>
            <div style="font-size: 12px; height: 100%; width: 100px" class="px-2 d-flex align-center">Sat.</div>
            <div style="font-size: 12px; height: 100%; width: 70px" class="px-2 d-flex align-center">Date</div>
            <div style="font-size: 12px; height: 100%; width: 40px" class="px-2 d-flex">
              Res
              <br>
              (m)
            </div>
            <div style="font-size: 12px; height: 100%; width: 40px" class="px-2">
              Cloud
              <br/>
              (%)
            </div>
            <div style="font-size: 12px; height: 100%; width: 20px" class="px-2"></div>
          </v-layout>
        </div>
        <v-data-iterator
            :items="data"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            hide-default-footer
            @page-count="pageCount = $event"
        >
          <template v-slot:no-data>
            <v-layout class="fill-height py-5" align-center justify-center column>
              <v-icon size="100">mdi-image-search-outline</v-icon>
              <span style="font-size: 14px">Nodata</span>
            </v-layout>
          </template>
          <template v-slot:default="props">
            <v-list>
              <v-list-item
                  v-for="(item, index) in props.items"
                  :key="index"
                  @mouseover="selectItem(item)"
                  @mouseleave="unSelectItem"
              >
                <v-list-item-action style="min-width: 0; min-height: 0; width: 30px" class="mr-2">
                  <v-checkbox
                      @change="toggleImageThumbnail(item)"
                      v-model="item.checked"
                      style="margin:0;padding:0"
                      hide-details
                      :disabled="item.disabled"
                  />
                </v-list-item-action>
                <v-list-item-content style="font-size: 12px;min-width: 100px; width: 100px" class="px-2">
                  {{ item.type }}
                </v-list-item-content>
                <v-list-item-content style="font-size: 12px;min-width: 70px; width: 70px" class="px-2">
                  {{ item.date }}
                </v-list-item-content>
                <v-list-item-content style="font-size: 12px; width: 20px" class="px-2">
                  {{ item.resolution }}
                </v-list-item-content>
                <v-list-item-content style="font-size: 12px; width: 20px" class="px-2">
                  {{ item.cloud_cover }}
                </v-list-item-content>
                <v-list-item-action style="width: 40px">
                  <v-layout>
                    <v-btn title="Zoom to" icon x-small color="accent" @click="() => $emit('zoomToBound', item)"
                           class="mr-1">
                      <v-icon size="20">mdi-crosshairs-gps</v-icon>
                    </v-btn>
                    <v-btn title="Info" icon x-small color="accent" @click="() => $refs.info.openDialog(item)"
                           class="ml-1">
                      <v-icon size="20">mdi-information</v-icon>
                    </v-btn>
                  </v-layout>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </template>
        </v-data-iterator>
      </div>
      <div>
        <div style="height: 45px; width: 100%;" class="secondary">
          <v-layout align-center class="fill-height px-3">
            <span style="font-size: 12px">{{ data.filter(val => val.checked).length }}/{{ data.length }} Selected</span>
            <v-spacer/>
            <v-btn small color="accent" class="mx-4" min-height="0" min-width="0" height="20" width="20"
                   :disabled="page === 1" @click="page = page - 1">
              <v-icon>mdi-menu-left</v-icon>
            </v-btn>
            <span style="font-size: 12px"
                  v-if="page !== pageCount">{{ 1 + 20 * (page - 1) }} - {{ page * 20 }} of {{ data.length }}</span>
            <span style="font-size: 12px"
                  v-else>{{ 1 + 20 * (page - 1) }} - {{ data.length }} of {{ data.length }}</span>
            <v-btn small color="accent" class="mx-4" min-height="0" min-width="0" height="20" width="20"
                   @click="page = page + 1" :disabled="page === pageCount">
              <v-icon>mdi-menu-right</v-icon>
            </v-btn>
          </v-layout>
        </div>
        <div style="height: 45px; width: 100%;" class="secondary">
          <v-layout class="fill-height px-3" align-center>
            <v-btn outlined color="accent" min-height="0" min-width="0" height="35" width="35" class="mr-2"
                   @click="deSelectAll" title="Clear Selected Items">
              <v-icon>mdi-broom</v-icoN>
            </v-btn>
            <v-btn v-if="currentUser" outlined color="accent" min-height="0" min-width="0" height="35" width="35"
                   @click="openSearchManagementDialog" title="Search Management">
              <v-icon>mdi-content-save</v-icoN>
            </v-btn>
            <v-btn :disabled="!currentUser" color="accent" class="flex-grow-1 ml-2" @click="openQuotaForm">Get a quota</v-btn>
          </v-layout>
        </div>
      </div>
    </div>
    <DatasetForm ref="datasetForm" @selectDataSource="val => dataset = val"/>
    <DataAlertDialog ref="dataAlertDialog"/>
    <SearchManagementDialog ref="searchManagementDialog" @loadOldData="loadOldData"/>
    <ImageInfo ref="info"/>
    <Quota ref="quota"/>
  </div>
</template>

<script>
import geocoder from '@/components/home/geocoder/geocoder'
import DatePicker from "@/components/DatePicker";
import DatasetForm from "@/components/home/dataset/DatasetForm";
import {getThumbnail, search} from "@/backend";
import bbox from '@turf/bbox'
import DataAlertDialog from "@/components/home/data-alert/DataAlertDialog";
import SearchManagementDialog from "@/components/home/save-search/SearchManagementDialog";
import ImageInfo from "@/components/home/detail/ImageInfo";
import Store from "@/store";
import Quota from "@/components/home/quotation/Quota";
import {mapState} from "@/store/ults";
import config from '@/config.json'

let startDate = new Date()
startDate.setMonth(startDate.getMonth() - 1)
export default {
  components: {
    Quota,
    ImageInfo,
    DatasetForm,
    geocoder,
    DatePicker,
    DataAlertDialog,
    SearchManagementDialog,
  },
  name: "Filter-bar",
  data() {
    return {
      loading: false,
      currentAoi: undefined,
      dataset: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      headers: [
        {text: 'Action', sortable: false, value: 'action'},
        {text: 'Sat.', align: 'start', width: 50, sortable: false, value: 'type',},
        {text: 'Date.', align: 'start', width: 50, sortable: false, value: 'date',},
        {text: 'Res (m)', sortable: false, value: 'resolution'},
        {text: 'Cloud (%)', sortable: false, value: 'cloud_cover'},
        {text: '', sortable: false, value: 'info'},
      ],
      data: [],
      dataSelected: [],
      cloudCover: 90,
      resolution: [0, 23],
      isDisplayFilterForm: true,
      startDate: startDate.toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10)
    }
  },
  mounted() {
    const data = require('@/assets/datasource/data.json')
    for (const key in data) {
      this.dataset[key] = data[key][0].types.map(val => val.value)
    }
  },
  computed: {
    ...mapState("auth", ["currentUser"])
  },
  methods: {
    loadOldData(oldParam) {
      this.dataset = oldParam.dataset
      let oldDataset = {}
      const dataset = require("@/assets/datasource/data.json")
      for (const datasetKey in oldParam.dataset) {
        oldDataset[datasetKey] = dataset[datasetKey][0].types
      }
      Store.commit('s', ['dataset', 'currentDataset', oldDataset])
      this.startDate = oldParam.filter.start
      this.endDate = oldParam.filter.end
      this.cloudCover = oldParam.filter.cloud_cover
      this.resolution = oldParam.filter.resolution
      this.$emit('setCurrentAOI', {
        features: [oldParam.geojson],
        type: 'FeatureCollection'
      })
    },
    async toggleImageThumbnail(currentImage) {
      if (currentImage.checked) {
        try {
          this.data = this.data.map(el => {
            return {
              ...el, 
              disabled: currentImage.dataset == 'latconnect60' && el.dataset !== 'latconnect60' || currentImage.dataset !== 'latconnect60' && el.dataset == 'latconnect60'
            }
          })
          this.dataSelected.push(currentImage)
          // this.loading = true
          const res = await getThumbnail({
            dataset: currentImage.dataset,
            thumbnail: currentImage.thumbnail
          })
          const url = (res.data.url.includes("http") ? "" : `${config.backend_discovery_IG}`) + res.data.url
          this.$emit('addImageToMap', url, currentImage)
        } catch (e) {
          console.log(e.message)
        } finally {
          // this.loading = false
        }
      } else {
        this.dataSelected = this.dataSelected.filter(el => el.id !== currentImage.id)
        if(!this.dataSelected.length) {
          this.data = this.data.map(el => {
            return {
              ...el, 
              disabled: false
            }
          })
        }
        this.$emit('removeImage', currentImage)
      }
    },
    async selectItem(image) {
      this.$emit('addFootPrint', image.geometry, image.id)
    },
    unSelectItem() {
      this.$emit('removeFootPrint')
    },
    openDatasetForm() {
      this.$refs.datasetForm.openDialog()
    },
    deSelectAll() {
      this.data.forEach(val => {
        val.checked = false
      })
      this.$emit('clearMap')
    },
    SearchImage(geojson) {
      this.setAOI(geojson)
      this.searchData()
    },
    setAOI(geojson) {
      this.currentAoi = geojson
    },
    async searchData() {
      this.deSelectAll()
      this.data = []
      if (!this.currentAoi) return (this.$store.commit('message/SHOW_ERROR', 'AOI is required'))
      if (!this.dataset) return (this.$store.commit('message/SHOW_ERROR', 'Dataset is required'))
      let data = {
        dataset: this.dataset,
        filter: {
          cloud_cover: this.cloudCover,
          end: this.endDate,
          geometry: this.currentAoi.features[0].geometry,
          resolution: this.resolution,
          start: this.startDate
        }
      }
      try {
        this.loading = true
        const res = await search(data)
        this.data = res.data.data
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        this.isDisplayFilterForm = false
        this.loading = false
      }
    },
    flyToLocation(location) {
      this.$emit('submitZoom', bbox(JSON.parse(location.bbox)))
    },
    openDataAlertDialog() {
      this.$refs.dataAlertDialog.openDataAlertDialog()
    },
    openSearchManagementDialog() {
      this.$refs.searchManagementDialog.openSearchManagementDialog({
        dataset: this.dataset,
        filter: {
          cloud_cover: this.cloudCover,
          end: this.endDate,
          resolution: this.resolution,
          start: this.startDate
        },
      })
    },
    openQuotaForm() {
      if (this.currentAoi && this.data.filter(val => val.checked).length) {
        this.$refs.quota.openDialog({
          images: this.data.filter(val => val.checked),
          search: {
            dataset: this.dataset,
            filter: {
              cloud_cover: this.cloudCover,
              end: this.endDate,
              resolution: this.resolution,
              start: this.startDate
            },
            geojson: this.currentAoi.features[0],
          }
        })
      } else {
        this.$store.commit('message/SHOW_ERROR', 'Data does not exist')
      }
    },
    resetFilter() {
      this.cloudCover = 90
      this.resolution = [0, 23]
      this.startDate = startDate.toISOString().substr(0, 10)
      this.endDate = new Date().toISOString().substr(0, 10)
    },
    clear () {
      this.data = []
    }
  }
}
</script>

<style scoped>
/deep/
.data-table-custom td {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

/deep/
.data-table-custom th {
  padding-right: 4px !important;
  padding-left: 4px !important;
}
</style>
