<template>
  <v-dialog v-model="dialog" max-width="570" persistent>
    <v-card>
      <v-card-title class="popup-header">
        <span>Dataset</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeDatasetForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text style="height: 375px; overflow-y: auto" class="my-3">
        <h4>Satellite Companies</h4>
        <div class="d-flex justify-center flex-column">
          <template v-for="(item, index) in data">
            <v-treeview
                class="mt-2"
                style="background: #f1f9ff"
                selectable
                dense
                v-model="selection[index]"
                :items="item"
                item-children="types"
                expand-icon="mdi-chevron-down"
                item-key="value"
                :key="index"
                return-object
            />
          </template>
        </div>
      </v-card-text>
      <v-card-actions class="pb-3 ml-4">
        <v-btn
          style="width: 150px"
          small
          color="primary"
          class="mr-5"
          @click="selectAll"
          outlined
          >Select All
        </v-btn>
        <v-btn
          small
          style="width: 150px"
          color="primary"
          @click="selectNone"
          class="mr-5"
          outlined
          >Select none
        </v-btn>
        <v-btn
          small
          style="width: 150px"
          @click="selectDatasource"
          color="primary"
          >Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Store from '@/store'
export default {
  name: "DatasetForm",
  data() {
    return {
      dialog: false,
      data: [],
      selection: {},
    };
  },
  computed: {
    ...mapGetters('dataset', ['currentDataset'])
  },
  created() {
    this.data = require("@/assets/datasource/data.json")
    this.selectAll()
    Store.commit('s', ['dataset', 'currentDataset', JSON.parse(JSON.stringify(this.selection))])
  },
  methods: {
    openDialog() {
      this.selection = JSON.parse(JSON.stringify(this.currentDataset))
      this.dialog = true
      this.resetComponent()
    },
    selectAll() {
      for (const key in this.data) {
        this.selection[key] = this.data[key][0].types.map((val) => {
          return {
            name: val.name,
            value: val.value,
          };
        });
      }
      this.resetComponent()
    },
    selectNone() {
      this.selection = {}
      this.resetComponent()
    },
    selectDatasource() {
      Store.commit('s', ['dataset', 'currentDataset', JSON.parse(JSON.stringify(this.selection))])
      let dataSource = {}
      let check = false
      for (const key in this.data) {
        dataSource[key] = this.selection[key].map((value) => value.value)
        if (dataSource[key].length) check = true
      }
      if (!check)
        return this.$store.commit(
          "message/SHOW_ERROR",
          "Please provide at least 1 dataset !"
        )
      this.$emit("selectDataSource", dataSource)
      this.dialog = false
    },
    closeDatasetForm() {
      this.dialog = false
    },
    resetComponent() {
      const temp = JSON.parse(JSON.stringify(this.data))
      this.data = []
      this.data = temp
    },
  },
}
</script>

<style scoped>
</style>
