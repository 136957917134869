<template>
  <v-dialog v-model="dialog" width="1140" persistent>
    <v-card>
      <v-card-title class="popup-header">
        <span>Get a quota</span>
        <v-spacer />
        <v-btn icon small dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-5">
        <div class="d-flex" style="height: 500px">
          <quotation-latconnect-60
            class="fill-height"
            ref="latconnect60Source"
            v-if="listImage.length && listImage[0].dataset == 'latconnect60'"
            :listImage.sync="listImage"
            :search="search"
          ></quotation-latconnect-60>
          <quotation-other-source
            v-else
            ref="otherSource"
            :listImage.sync="listImage"
            :search="search"
          ></quotation-other-source>
          <div
            class="d-flex column fill-height py2 px-3"
            style="flex: 2.4; overflow-y: auto"
          >
            <v-data-table
              :loading="loading"
              style="width: 100%; height: 100%"
              fixed-header
              :headers="headers"
              :items="listImage"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:item.image="{ item }">
                <div
                  style="
                    width: 60px;
                    height: 60px;
                    border: 1px solid lightgray;
                    border-radius: 5px;
                    text-align: center;
                  "
                  class="d-flex align-center pa-1 my-2"
                >
                  <img
                    :loading="loading"
                    v-if="item.url"
                    :src="item.url"
                    alt="thumbnail"
                    style="max-height: 50px"
                    width="50"
                  />
                </div>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn icon small color="error" @click="removeImage(item)">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          color="primary"
          text
          :disabled="!listImage.length"
          @click="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getThumbnail } from "@/backend";
import config from "@/config.json";
import QuotationOtherSource from "./QuotationOtherSource.vue";
import QuotationLatconnect60 from "./QuotationLatconnect60.vue";
export default {
  components: { QuotationOtherSource, QuotationLatconnect60 },
  name: "Quota",
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      search: {},
      headers: [
        {
          text: "Image",
          align: "start",
          sortable: false,
          value: "image",
        },
        {
          text: "Sat.",
          align: "start",
          sortable: false,
          value: "type",
        },
        {
          text: "Date",
          align: "start",
          sortable: false,
          value: "date",
        },
        {
          text: "Res.",
          align: "end",
          width: 100,
          sortable: false,
          value: "resolution",
        },
        {
          text: "Cloud",
          align: "end",
          width: 100,
          sortable: false,
          value: "cloud_cover",
        },
        {
          text: "",
          align: "center",
          width: 70,
          sortable: false,
          value: "action",
        },
      ],
      listImage: [],
    };
  },
  methods: {
    async openDialog(data) {
      this.dialog = true;
      this.search = JSON.parse(JSON.stringify(data.search));
      this.listImage = JSON.parse(JSON.stringify(data.images));
      for (let i = 0; i < this.listImage.length; i++) {
        if (!this.listImage[i].url) {
          try {
            this.loading = true;
            const res = await getThumbnail({
              dataset: this.listImage[i].dataset,
              thumbnail: this.listImage[i].thumbnail,
            });
            this.listImage[i].url =
              (res.data.url.includes("http")
                ? ""
                : `${config.backend_discovery_IG}`) + res.data.url;
          } catch (e) {
            console.log(e);
          } finally {
            this.loading = false;
          }
        }
      }
    },
    removeImage(currentImage) {
      const index = this.listImage.findIndex((val) => val === currentImage);
      if (index > -1) this.listImage.splice(index, 1);
    },
    async submit() {
      if (this.$refs.otherSource) this.$refs.otherSource.submit();
      if (this.$refs.latconnect60Source) this.$refs.latconnect60Source.submit();
    },
  },
};
</script>

<style scoped>
</style>
