<template>
  <v-dialog
    v-model="dialog"
    width="400"
    >
    <v-card>
      <v-card-text class="pa-5" style="background-color: #001f39">
        <div class="d-flex align-center justify-center flex-column" style="width: 100%">
          <span style="font-size: 38px; font-weight: 800; color: white" class="pt-5">401</span>
          <p style="font-size: 16px; color: #7cc6ff;" class="mt-8">Unauthorized</p>
          <div style="border-bottom: 2px solid #7cc6ff; width: 70px"/>
          <div class="d-flex align-center justify-center pt-8 pb-5" style="width: 100%">
            <v-btn color="accent" @click="login">Log in again</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import config from "@/config.json";

export default {
  name: "UnauthorizedDialog",
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    openDialog () {
      this.dialog = true
    },
    login () {
      window.location.href = `${config.home_page}/signIn.html?redirect=${config.app}?token=`
    }
  }
}
</script>

<style scoped>

</style>
