<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="400"
  >
    <v-card>
      <v-card-title class="popup-header">
        <span>Rename</span>
        <v-spacer/>
        <v-btn icon small dark @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="pa-5">
        <v-form v-model="valid" ref="formData">
          <v-text-field
              v-model="name"
              :rules="[rules.required]"
              filled
              dense
              background-color="#F1F9FF"
              label="Name">
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn :loading="loading" text color="accent" @click="submitRename">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RenameDialog",
  props: {
    action: {
    }
  },
  data () {
    return {
      dialog: false,
      rules: {
        required: value => !!value || 'Name is required',
      },
      name: '',
      id: '',
      loading: false,
      valid: false
    }
  },
  methods: {
    openDialog (name, id) {
      this.id = id
      this.name = JSON.parse(JSON.stringify(name))
      this.dialog = true
    },
    async submitRename () {
      this.$refs.formData.validate()
      if (!this.valid) return
      try {
        this.loading = true
        const res = await this.action({id: this.id, data: {name: this.name}})
        this.$emit('fetchData')
        this.$store.commit('message/SHOW_SUCCESS', res.data.message)
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        this.loading = false
        this.dialog = false
      }
    }
  }
}
</script>

<style scoped>

</style>
