<template>
  <v-layout class="fill-height">
    <v-layout style="width: 400px; position: absolute; top: 0; left: 0; z-index: 2;" fill-height v-show="isLayerControl">
      <FilterBar
          ref="filterBar"
          @submitZoom="bbox => $refs.mapView.submitZoom(bbox)"
          @setCurrentAOI="geojson => $refs.mapView.drawAoi(geojson)"
          @clearMap="() => $refs.mapView.clearMap()"
          @addFootPrint="(geometry, id) => $refs.mapView.addFootPrint(geometry, id)"
          @removeFootPrint="() => $refs.mapView.removeFootPrint()"
          @addImageToMap="(url, currentImage) => $refs.mapView.addImage(url, currentImage)"
          @removeImage="image => $refs.mapView.removeImage(image)"
          @zoomToBound="image => $refs.mapView.zoomToBound(image)"
          @closeControl="isLayerControl = false"
      />
    </v-layout>
    <v-layout style="height: 100%; width: 100%;">
      <BaseMap
          @setAoi="val => $refs.filterBar.setAOI(val)"
          @changeAOI="val => $refs.filterBar.SearchImage(val)" ref="mapView"/>
    </v-layout>
    <v-layout style="width: 312px; position: absolute; top: 0; right: 0; z-index: 2" fill-height v-show="isAOI">
      <AOISideBar
          @closeAOISideBar="isAOI = false"
          @deleteAll="clearMap"
          @changeMode="mode => $refs.mapView.changeMode(mode)"
          @addGeojsonToMap="data => $refs.mapView.addRegionBoundary(data)"
      />
    </v-layout>
    <v-btn color="button" height="45" width="45" min-width="0" style="position: absolute; top: 10px; left: 10px" @click="isLayerControl = true" v-show="!isLayerControl">
      <div>
        <v-icon >mdi-image-search-outline</v-icon>
        <div style="font-size: 10px"></div>
      </div>
    </v-btn>
    <v-btn color="button" height="45" width="45" min-width="0" style="position: absolute; top: 10px; right: 10px" @click="isAOI = true" v-show="!isAOI">
      <div>
        <v-icon >mdi-selection-search</v-icon>
        <div style="font-size: 10px">AOI</div>
      </div>
    </v-btn>
    <v-btn color="button" height="45" width="45" min-width="0" style="position: absolute; top: 110px; right: 10px" @click="clearMap" >
      <div>
        <v-icon >mdi-broom</v-icon>
        <div style="font-size: 10px">Clear</div>
      </div>
    </v-btn>
  </v-layout>
</template>

<script>
import FilterBar from './FilterBar'
import AOISideBar from "@/components/home/aoi/AOISideBar";
import BaseMap from "@/components/home/BaseMap";
export default {
  components: { FilterBar, AOISideBar, BaseMap },
  name: "index",
  data () {
    return {
      isAOI: false,
      isLayerControl: true,
    }
  },
  methods: {
    clearMap () {
      this.$refs.filterBar.clear()
      this.$refs.mapView.deleteAll()
    }
  }
}
</script>

<style scoped>

</style>
