<template>
  <v-dialog v-model="isShowDialog" persistent max-width="418">
    <v-card>
      <v-card-title class="popup-header">
        <span>Data Alert</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeAlertDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-5" style="min-height: 386px; max-height: 70vh; overflow-y: auto">
        <div style="width: 100%; text-align: center">
          <p>
            Setup an alert to receive an email when new imagery matching the
            parameters below is collected.
          </p>
        </div>
        <v-row class="justify-center">
          <v-col cols="12">
            <p>Maximum acceptable resolution (in meters, m):</p>
            <v-slider
              v-model="form.resolution"
              :thumb-size="20"
              thumb-label="always"
              :max="23"
              dense
            ></v-slider>
            <v-select
              background-color="#F1F9FF"
              v-model="form.month"
              :items="Array.from({ length: 12 }, (_, index) => index + 1)"
              filled
              label="How long should we search for imagery? (Month)"
              dense
              hide-details
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-radio-group v-model="radio" class="mt-0">
              <template v-slot:label>
                <div>Define area of interest:</div>
              </template>
              <v-radio :value="0" label="Use on-screen polygon"> </v-radio>
              <v-radio :value="1" label="Upload GeoJSON"> </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="radio" cols="12">
            <div
              class="drop"
              @drop="handleDrop"
              @dragover="handleDragover"
              @dragenter="handleDragover"
            >
              <input
                ref="excel-upload-input"
                class="excel-upload-input"
                type="file"
                accept=".geojson"
                @change="handleClick"
              />
              <p class="mb-5">
                To upload, Drag and Drop the file or Click here.
              </p>
              <v-btn @click="handleUpload" color="primary"
                ><v-icon>mdi-upload</v-icon></v-btn
              >

              <div
                class="text-center pt-3"
                style="width: 100%; height: 20px; color: #1976d2"
              >
                {{ file_name }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            text
            color="accent"
            @click="submit"
            :loading="loading"
        >Submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createDataAlert } from "@/backend";
import { mapState } from '@/store/ults';
export default {
  data() {
    return {
      isShowDialog: false,
      loading: false,
      radio: 0,
      file_name: "",
      form: {
        geojson: "",
        resolution: 2,
        month: 6,
      },
    };
  },

  computed: {
      ...mapState('aoi', ['currentAOI'])
  },

  watch: {
    radio(val) {
      this.form.geojson = "";
      this.file_name = "";
    },
  },

  methods: {
    openDataAlertDialog() {
      this.isShowDialog = true;
    },
    async submit() {
      try {
        if (this.radio === 0) {
          if (!this.currentAOI)
            return this.$store.commit(
              "message/SHOW_ERROR",
              "No polygon is defined"
            );
          this.form.geojson = this.currentAOI;
        } else {
          if (!this.form.geojson)
            return this.$store.commit(
              "message/SHOW_ERROR",
              "Please upload a polygon"
            );
        }
        this.loading = true;
        await createDataAlert(this.form);
        this.showDialog = false;
        this.$store.commit(
          "message/SHOW_SUCCESS",
          "Success! You will receive an email from us"
        );
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    uploadFile(file) {
      if (file.size > 15 * 1024 * 1024) {
          console.log(123);
        this.$store.commit(
          "message/SHOW_ERROR",
          "Only support file size less than 15 MB!"
        );
        Object.assign(this.$data, this.$options.data());
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.geojson = JSON.parse(e.target.result);
        };
        this.file_name = file.name;
        reader.readAsText(file);
      }
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$store.commit(
          "message/SHOW_ERROR",
          "Only support uploading one file!"
        );
        return;
      }
      this.uploadFile(files[0]);
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = "copy";
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      if (!rawFile) return;
      this.uploadFile(rawFile);
    },
    closeAlertDialog() {
        Object.assign(this.$data, this.$options.data());
    }
  },
};
</script>
<style lang="scss" scoped>
.drop {
  text-align: center;
  border: 3px dashed #bbb;
  padding: 20px 0;
}
.excel-upload-input {
  display: none;
  z-index: -9999;
}
</style>
