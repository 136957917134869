<template>
  <v-dialog color="#003F71" v-model="isShowDialog" persistent max-width="500px">
    <v-card :loading="loading">
      <v-card-title class="popup-header">
        <span>Search management</span>
        <v-spacer></v-spacer>
        <v-btn dark icon small @click="closeDialog"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text class="pa-5" style="max-height: 70vh; overflow-y: auto">
          <div class="d-flex align-center justify-center" v-if="!searchs.length">
            <p>No search found</p>
          </div>
         <v-list dense>
          <v-list-item-group color="primary">
            <v-list-item
                style="border-bottom: 1px solid lightgray"
                v-for="(item, i) in searchs"
                :key="i"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-list-item-subtitle>Date Created: {{ new Date(item.created_at).toGMTString() }}</v-list-item-subtitle>
                <v-list-item-subtitle>Start: {{ item.payload.filter.start }}</v-list-item-subtitle>
                <v-list-item-subtitle>End: {{ item.payload.filter.end }}</v-list-item-subtitle>
                <v-list-item-subtitle>Resolution: {{ item.payload.filter.resolution[0] }} m - {{ item.payload.filter.resolution[1] }} m</v-list-item-subtitle>
                <v-list-item-subtitle>Cloud coverage: <{{ item.payload.filter.cloud_cover }}%</v-list-item-subtitle>
                <v-list-item-subtitle>Dataset: {{ item.payload.dataset_length }}/18</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-layout>
                  <v-btn
                      @click="loadSavedSearch(item)"
                      fab
                      x-small
                      icon
                      title="Search"
                  >
                    <v-icon color="primary">mdi-magnify</v-icon>
                  </v-btn>
                  <v-btn
                      @click="deleteSearch(item.id)"
                      fab
                      x-small
                      icon
                      title="Delete"
                  >
                    <v-icon color="red">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-layout>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
          </v-list>
      </v-card-text>
      <v-card-actions>
        <v-layout justify-center>
          <v-btn color="primary" :disabled="!currentAOI" @click="saveCurrentSearch">SAVE CURRENT SEARCH</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
    <SaveSearchDialog
      ref="saveSearchDialog"
      :searchs="searchs"
      @openSearchManagementDialog="openSearchManagementDialog"
    />
  </v-dialog>
</template>

<script>
import { getSavedSearches, deleteSavedSearch } from "@/backend";
import { mapState } from '@/store/ults'
import SaveSearchDialog from '@/components/home/save-search/SaveSearchDialog'

export default {
  components: {SaveSearchDialog},
  data() {
    return {
      isShowDialog: false,
      loading: false,
      searchs: [],
      searchFilter: {}
    };
  },

  computed: {
      ...mapState('aoi', ['currentAOI']),
  },

  watch: {
    isShowDialog(val) {
      if (val) this.getListSearch();
    },
  },

  methods: {
    openSearchManagementDialog(data) {
      this.searchFilter = data
      this.isShowDialog = true;
    },
    async getListSearch() {
      try {
        this.loading = true;
        const { data } = await getSavedSearches();
        this.searchs = data.data;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    async deleteSearch(id) {
      this.loading = true;
      await deleteSavedSearch(id);
      this.$store.commit("message/SHOW_SUCCESS", "Deleted Search Successfully");
      this.loading = false;
      await this.getListSearch();
    },
    saveCurrentSearch() {
      this.isShowDialog = false
      this.$refs.saveSearchDialog.openSaveSearchDialog(this.searchFilter)
    },
    loadSavedSearch (filterParam) {
      this.$emit('loadOldData', filterParam.payload)
      this.isShowDialog = false
    },
    closeDialog() {
      Object.assign(this.$data, this.$options.data());
    }
  },
};
</script>
