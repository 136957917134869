import {uploadShapeFile, addAOI} from '@/backend'
const list = ['SHP', 'PRJ', 'DBF', 'SHX']
export class AOI {
  check (files) {
    if (!files.length) return false
    let types = []
    for (let i = 0; i < files.length; i++) {
      if (files[0].name.split('.').shift().toUpperCase() !== files[i].name.split('.').shift().toUpperCase()) return false
      if (list.includes(files[i].name.split('.').pop().toUpperCase())) {
        types.push(files[i].name.split('.').pop().toUpperCase())
      }
    }
    if (files.length === 1) {
      switch (files[0].name.split('.').pop().toUpperCase()) {
        case 'GEOJSON':
        case 'KMZ':
        case 'KML':
        case 'ZIP':
        case 'GML':
          return true
        default:
          return false
      }
    } else if (types.length === 4) {
      return true
    }
    return false
  }
  transformData (files) {
    let currentFiles = []
    if (files.length === 1) {
      switch (files[0].name.split('.').pop().toUpperCase()) {
        case 'GEOJSON':
        case 'KMZ':
        case 'KML':
        case 'ZIP':
        case 'GML':
          return files
        default:
          return console.log('Check error')
      }
    } else {
      for (let i = 0; i < files.length; i++) {
        if (files[0].name.split('.').shift().toUpperCase() !== files[i].name.split('.').shift().toUpperCase()) return false
        if (list.includes(files[i].name.split('.').pop().toUpperCase())) {
          currentFiles.push(files[i])
        }
      }
      return currentFiles
    }
  }
  async upload (files) {
    const formData = new FormData()
    files.forEach((file, index) => {
      formData.append(file.name.split('.').pop(), file)
      formData.append('type', files.length === 4 ? 'shp' : file.name.split('.').pop())
    })
    const filename = files[0].name.replace(`.${files[0].name.split('.').pop()}`, '')
    formData.append('name', filename)
    return await uploadShapeFile(formData)
  }
  async saveAOI ( name, aoi) {
    return await addAOI({
      name: name,
      geom: aoi
    })
  }
}
const AOIUpload = new AOI()

export default AOIUpload
