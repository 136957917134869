<template>
  <v-menu
    ref="menu"
    v-model="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        filled
        dense
        background-color="#F1F9FF"
        v-model="date"
        :label="label"
        hint="MM-DD-YYYY format"
        prepend-inner-icon="mdi-calendar-range"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="rules"
        :disabled="disabled"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :min="min"
      :max="max"
      @change="dateMenu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {type: String, default: undefined},
    min: {type: String, default: undefined},
    max: {type: String, default: undefined},
    label: {type: String, default: undefined},
    rules: {type: Array, default: undefined},
    disabled: {type: Boolean, default: false}
  },
  watch: {
    value (val) {
      this.dateModel = this.value
    }
  },
  computed: {
    date: {
      get () {
        return this.dateModel
      },
      set (val) {
        this.dateModel = val
        this.$emit('input', this.dateModel)
      }
    }
  },
  data: () => ({
    dateMenu: false,
    dateModel: undefined
  }),

  created () {
    this.dateModel = this.value
  }
}
</script>
