<template>
  <v-card height="100%" flat>
    <v-data-table
      height="100%"
      style="height: 77%; border: 1px lightgrey solid"
      fixed-header
      hide-default-footer
      :headers="orderHeaders"
      :items="orders"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      :loading="loading"
      :items-per-page="15"
      loading-text="Loading... Please wait"
      class="row-pointer order-table"
      :server-items-length="totalItem"
      :options.sync="options"
      :page.sync="page"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
    >
      <template v-slot:no-data>
        <v-layout align-center justify-center class="py-5" column>
          <img
            :src="require('@/assets/images/orders-icon-26.jpg')"
            width="100"
          />
          <span style="font-size: 16px; color: #777c85" class="pl-4 pt-3"
            >No data order</span
          >
        </v-layout>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ new Date(item.created_at).toLocaleDateString("en-GB") }}
      </template>
      <template v-slot:[`item.image_count`]="{ item }">
        {{ item.order_items.length }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          style="width: 180px"
          small
          class="ma-2"
          :color="
            statuses.find((status) => status.value.toUpperCase() == item.status)
              .color
          "
          text-color="white"
        >
          <v-layout
            justify-center
            align-center
            class="fill-height"
            style="width: 100%"
          >
            {{ item.status }}
          </v-layout>
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <v-data-table
            class="elevation-0 list-image"
            :headers="orderItemHeaders"
            :items="item.order_items"
            item-key="id"
            hide-default-footer
            disable-filtering
            disable-pagination
            disable-sort
          >
            <template v-slot:[`item.image.acquired_date`]="{ item }">
              {{
                new Date(item.image.acquired_date).toLocaleDateString("en-GB")
              }}
            </template>
            <template v-slot:[`item.expired_at`]="{ item }">
              {{
                item.expired_at && new Date(item.expired_at).toLocaleString("en-GB")
              }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                style="width: 90px"
                small
                class="ma-2"
                :color="
                  statuses.find(
                    (status) => status.value.toUpperCase() == item.status
                  ).color
                "
                text-color="white"
              >
                <v-layout
                  justify-center
                  align-center
                  class="fill-height"
                  style="width: 100%"
                >
                  {{ item.status }}
                </v-layout>
              </v-chip>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <!--                <v-btn-->
              <!--                    icon-->
              <!--                    color="primary"-->
              <!--                    title="Preview"-->
              <!--                    @click="showPreview(item)"-->
              <!--                >-->
              <!--                  <v-icon>mdi-eye-outline</v-icon>-->
              <!--                </v-btn>-->
              <v-btn
                :disabled="!item.location"
                icon
                color="success"
                title="Download"
                @click="download(item.location)"
              >
                <v-icon>mdi-download-outline</v-icon>
              </v-btn>

              <!-- <v-btn
                :disabled="!item.link || item.status != 6"
                icon
                color="primary"
                title="Import to Eofactory"
                @click="importToEOF(item)"
              >
                <v-icon>mdi-import</v-icon>
              </v-btn> -->
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        class="ma-3 mt-6"
        :total-visible="5"
        v-model="page"
        :length="pageCount"
        id="task_pagination"
      ></v-pagination>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "@/store/ults";
import {
  getLatconnect60Orders,
  downloadLatconnect60OrderImage,
} from "@/backend";
export default {
  name: "Latconnect60OrderList",
  data() {
    return {
      options: {},
      page: 1,
      totalItem: 0,
      pageCount: 0,
      expanded: [],
      singleExpand: true,
      loaded: false,
      loading: false,
      orderHeaders: [
        {
          text: "Order ID",
          align: "center",
          sortable: false,
          value: "id",
        },
        { text: "Date", value: "created_at", align: "center" },
        { text: "Image Count", value: "image_count", align: "center" },
        { text: "Status", value: "status", align: "center" },
      ],
      orderItemHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "image.name",
        },
        { text: "Date", value: "image.acquired_date", align: "center" },
        { text: "Type", value: "image.satellite", align: "center" },
        { text: "Cloud", value: "image.cloud_cover", align: "center" },
        {
          text: "Resolution",
          value: "image.resolution",
          align: "center",
          width: 20,
        },
        { text: "Expired At", value: "expired_at", align: "center" },
        { text: "Status", value: "status", align: "center", width: 30 },
        { text: "Action", value: "action", align: "center" },
      ],
      orders: [],
      statuses: [
        {
          value: "Created",
          color: "#006ec5",
        },
        {
          value: "Processing",
          color: "#009688",
        },
        {
          value: "Success",
          color: "#25db00",
        },
        {
          value: "Canceled",
          color: "#f2c94c",
        },
        {
          value: "Failed",
          color: "#eb5757",
        },
        {
          value: "Partial",
          color: "#d6dc01",
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.currentUser) this.getData();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const { data } = await getLatconnect60Orders({
          per_page: this.options.itemsPerPage,
          page: this.options.page,
        });
        this.totalItem = data.meta.total;
        this.pageCount = data.meta.last_page;
        this.orders = JSON.parse(JSON.stringify(data.data));
      } catch (error) {
        if(error.response.data.status === 401) {
          this.$store.commit("message/SHOW_ERROR", "Latconnect60 key is invalid")
        }
      } finally {
        this.loading = false;
      }
    },

    async download(location) {
      try {
        this.loading = true;
        const { data } = await downloadLatconnect60OrderImage(location);
        window.open(data.data.url, "_blank");
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
