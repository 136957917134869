<template>
  <div
    class="d-flex column fill-height flex-column py-2 px-3"
    style="flex: 1; border-right: 1px solid lightgray"
  >
    <v-form ref="formData" v-model="valid" class="fill-height">
      <v-text-field
        filled
        dense
        v-model="name"
        :rules="[rules.required]"
        label="Name"
        background-color="#F1F9FF"
      ></v-text-field>
      <v-text-field
        filled
        dense
        v-model="email"
        :rules="[rules.email]"
        label="Email"
        background-color="#F1F9FF"
      ></v-text-field>
      <v-text-field
        filled
        dense
        v-model="company"
        label="Company"
        background-color="#F1F9FF"
      ></v-text-field>
      <v-text-field
        filled
        dense
        type="number"
        v-model="phoneNumber"
        label="Phone number"
        background-color="#F1F9FF"
      ></v-text-field>
    </v-form>
    <span class="text-uppercase">Selected: {{ listImage.length }}</span>
  </div>
</template>

<script>
import { order } from "@/backend";
export default {
  name: "QuotationOtherSource",
  props: {
    listImage: { type: Array, default: () => [] },
    search: { type: Object, default: () => {} },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      rules: {
        required: (value) => !!value || "Name is required",
        email: (value) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            String(value).toLowerCase()
          ) || "Email is invalid",
      },
      valid: false,
      name: "",
      company: "",
      email: "",
      phoneNumber: "",
    };
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.listImage = [];
        this.name = "";
        this.email = "";
        this.phoneNumber = "";
        this.company = "";
        this.$refs.formData.resetValidation();
      }
    },
  },
  methods: {
    async submit() {
      this.$refs.formData.validate();
      if (!this.valid) return;
      try {
        this.loading = true;
        const res = await order({
          name: this.name,
          company: this.company,
          email: this.email,
          description: this.description,
          images: this.listImage,
          phone_number: this.phoneNumber,
          search: this.search,
        });
        this.$store.commit("message/SHOW_SUCCESS", res.data.message);
      } catch (e) {
        this.$store.commit("message/SHOW_ERROR", e.message);
      } finally {
        this.loading = false;
        this.dialog = false;
      }
    },
  },
};
</script>
