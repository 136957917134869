<template>
  <v-layout style="width: 100%; height: 100%">
    <OrderList/>
  </v-layout>
</template>

<script>
import OrderList from '@/components/order/OrderList'

export default {
    components: { OrderList }
}
</script>
