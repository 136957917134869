<template>
  <v-layout column fill-height style="border-bottom-left-radius: 5px; border-top-left-radius: 5px" class="elevation-1">
    <div style="width: 100%; height: 45px; border-top-left-radius: 5px" class="accent px-3">
      <v-layout fill-height align-center>
        <span style="line-height: 45px; color: #fff; width: 250px">AOI</span>
        <v-spacer/>
        <v-btn icon x-small style="background-color: white" @click="() => $emit('closeAOISideBar')">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-layout>
    </div>
    <v-layout fill-height style="background-color: white">
      <v-overlay absolute :value="loading">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-tabs
          v-model="tab"
          fixed-tabs
          hide-slider
          class="d-flex fill-height flex-column"
          background-color="secondary"
          :show-arrows="false"
      >
        <!-- Tabs -->
        <v-tab
            href="#search"
            style="min-width: 0; width: 65px; height: 45px"
            v-bind:class="[ tab === 'search' ? 'active-header' : 'header']"
        >
          <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M12 4C14.2 4 16 5.8 16 8C16 10.1 13.9 13.5 12 15.9C10.1 13.4 8 10.1 8 8C8 5.8 9.8 4 12 4ZM12 2C8.7 2 6 4.7 6 8C6 12.5 12 19 12 19C12 19 18 12.4 18 8C18 4.7 15.3 2 12 2ZM12 6C10.9 6 10 6.9 10 8C10 9.1 10.9 10 12 10C13.1 10 14 9.1 14 8C14 6.9 13.1 6 12 6ZM20 19C20 21.2 16.4 23 12 23C7.6 23 4 21.2 4 19C4 17.7 5.2 16.6 7.1 15.8L7.7 16.7C6.7 17.2 6 17.8 6 18.5C6 19.9 8.7 21 12 21C15.3 21 18 19.9 18 18.5C18 17.8 17.3 17.2 16.2 16.7L16.8 15.8C18.8 16.6 20 17.7 20 19Z"
                :fill="tab === 'search' ? '#003F71' : '#ffffff'"
            />
          </svg>
        </v-tab>
        <v-tab
            href="#saved_aoi"
            style="min-width: 0; width: 65px; height: 45px"
            v-bind:class="[ tab === 'saved_aoi' ? 'active-header' : 'header']"
        >
          <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M17 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V7L17 3ZM19 19H5V5H16.17L19 7.83V19ZM12 12C10.34 12 9 13.34 9 15C9 16.66 10.34 18 12 18C13.66 18 15 16.66 15 15C15 13.34 13.66 12 12 12ZM6 6H15V10H6V6Z"
                :fill="tab === 'saved_aoi' ? '#003F71' : '#ffffff'"
            />
          </svg>
        </v-tab>
        <v-tab
            href="#upload_aoi"
            style="min-width: 0; width: 65px; height: 45px"
            v-bind:class="[ tab === 'upload_aoi' ? 'active-header' : 'header']"
        >
          <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M9 10V16H15V10H19L12 3L5 10H9ZM12 5.8L14.2 8H13V14H11V8H9.8L12 5.8ZM19 18H5V20H19V18Z"
                :fill="tab === 'upload_aoi' ? '#003F71' : '#ffffff'"
            />
          </svg>
        </v-tab>
        <v-tab
            href="#draw"
            style="min-width: 0; width: 65px; height: 45px"
            v-bind:class="[ tab === 'draw' ? 'active-header' : 'header']"
        >
          <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M14 17H17V14H19V17H22V19H19V22H17V19H14V17ZM12 17V19H9V17H12ZM7 17V19H3V15H5V17H7ZM3 13V10H5V13H3ZM3 8V4H7V6H5V8H3ZM9 4H12V6H9V4ZM15 4H19V8H17V6H15V4ZM19 10V12H17V10H19Z"
                :fill="tab === 'draw' ? '#003F71' : '#ffffff'"
            />
          </svg>
        </v-tab>

        <!-- Tab Items -->
        <v-tabs-items
            v-model="tab"
            class="fill-height"
            style="border-radius: 0px 0px 0px 8px"
        >
          <!-- Search -->
          <v-tab-item value="search" class="fill-height">
            <v-card flat class="fill-height">
              <v-card-text class="fill-height pa-0 d-flex flex-column">
                <div style="height: 45px; width: 100%; background-color: #F1F9FF; text-transform: uppercase">
                <span
                    style="font-size: 13px; color: #003F71; line-height: 45px"
                    class="px-3"
                >Create By Region Boundary</span
                >
                </div>
                <v-layout class="pa-3" column>
                  <div>
                    <v-autocomplete
                        filled
                        dense
                        background-color="backgroundInput"
                        v-model="country"
                        hide-details
                        item-text="name"
                        label="Country"
                        item-value="code"
                        return-object
                        :items="countries"
                        @input="changeCountry(country)"
                        :menu-props="{ maxWidth: 280, Width: 280 }"
                    />
                    <v-autocomplete
                        filled
                        dense
                        background-color="backgroundInput"
                        class="mt-3"
                        multiple
                        autocomplete="new-province"
                        v-model="province"
                        hide-details
                        item-text="name"
                        item-value="code"
                        label="State/Province"
                        return-object
                        :items="provinces"
                        @input="changeProvince(province)"
                        :menu-props="{ maxWidth: 280, Width: 280 }"
                    />
                    <v-autocomplete
                        filled
                        dense
                        background-color="backgroundInput"
                        class="mt-3"
                        v-if="province.length <= 1"
                        autocomplete="new-district"
                        v-model="selectedDistricts"
                        hide-details
                        item-text="name"
                        item-value="code"
                        multiple
                        label="District"
                        return-object
                        :items="districts"
                        @change="changeDistrict(selectedDistricts)"
                        :menu-props="{ maxWidth: 280, Width: 280 }"
                    />
                    <v-layout v-if="province.length >= 2" class="mt-5">
                      <v-btn
                          style="flex: 1; margin-right: 6px"
                          height="28"
                          outlined
                          color="primary"
                          @click="changeMergeStates('union')"
                          :disabled="province.length < 2"
                      ><span style="font-size: 12px">Union</span></v-btn
                      >
                      <v-btn
                          style="flex: 1; margin-left: 6px"
                          height="28"
                          outlined
                          color="primary"
                          @click="changeMergeStates('concavehull')"
                          :disabled="province.length < 2"
                      ><span style="font-size: 12px">Concave Hull</span></v-btn
                      >
                    </v-layout>
                    <v-layout v-if="selectedDistricts.length >= 2" class="mt-5">
                      <v-btn
                          style="flex: 1; margin-right: 6px"
                          height="28"
                          outlined
                          color="primary"
                          @click="changeMergeDistricts('union')"
                          :disabled="selectedDistricts.length < 2"
                      ><span style="font-size: 12px">Union</span></v-btn
                      >
                      <v-btn
                          style="flex: 1; margin-left: 6px"
                          height="28"
                          outlined
                          color="primary"
                          @click="changeMergeDistricts('concavehull')"
                          :disabled="selectedDistricts.length < 2"
                      ><span style="font-size: 12px">Concave Hull</span>
                      </v-btn>
                    </v-layout>
                    <v-autocomplete
                        filled
                        dense
                        background-color="backgroundInput"
                        class="mt-3"
                        autocomplete="new-subdistrict"
                        v-if="selectedDistricts.length < 2 && province.length <= 1"
                        v-model="subDistrict"
                        hide-details
                        item-text="name"
                        item-value="code"
                        label="Sub District"
                        return-object
                        :items="subDistricts"
                        @input="changeSubDistrict(subDistrict)"
                        :menu-props="{ maxWidth: 280, Width: 280 }"
                    />
                  </div>
                </v-layout>

                <div class="d-flex pa-3">
                  <v-btn
                      dark
                      height="36"
                      color="primary"
                      class="flex mr-2"
                      @click="openSaveCurrentAOI"
                  >
                    Save Current AOI
                  </v-btn>

                  <v-btn
                      outlined
                      width="36"
                      min-width="0"
                      height="36"
                      color="primary"
                      class="flex-none"
                      @click="() => $emit('deleteAll')"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Save AOI -->
          <v-tab-item value="saved_aoi" class="fill-height">
            <v-card flat class="fill-height">
              <v-card-text class="fill-height pa-0 d-flex flex-column">
                <div
                    style="height: 45px; width: 100%; background-color: #F1F9FF; text-transform: uppercase"
                >
                <span
                    style="font-size: 13px; color: #003F71; line-height: 45px"
                    class="px-3"
                >Select From Saved AOIs</span
                >
                </div>
                <v-layout column class="fill-height overflow-y-auto mt-2">
                  <v-list
                      flat
                      width="100%"
                      v-for="(item, index) in listAOI"
                      class="py-0"
                      :key="index"
                  >
                    <v-list-item :key="item.id" class="px-1">
                      <v-list-item-avatar width="40" class="pl-2 pt-3">
                        <v-checkbox
                            on-icon="mdi-radiobox-marked"
                            off-icon="mdi-radiobox-blank"
                            dense
                            @change="selectAOI(item, index)"
                            :key="index"
                            v-model="item.isView"
                        ></v-checkbox>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                            style="font-size: 14px"
                            :title="item.name"
                            v-text="item.name"

                        />
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-layout>
                          <v-btn
                              title="Download"
                              icon
                              small
                              :id="`aoi_item_downloadButton_${index}`"
                              :download="item.name + '.geojson'"
                              href="””"
                              @click="downloadPolygon(item, index)"
                          >
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                  d="M10.25 3.75V3.25H9.75H8.25H7.75V3.75V7.75H7.3725H6.16539L7.01895 8.60355L8.64645 10.2311L9 10.5846L9.35355 10.2311L10.9811 8.60355L11.8346 7.75H10.6275H10.25V3.75ZM6.75 7.25H7.25V6.75V2.75H10.75V6.75V7.25H11.25H13.0429L9 11.2929L4.95711 7.25H6.75ZM4.25 14.5V14H13.75V14.5H4.25Z"
                                  fill="black"
                                  stroke="#006EC5"
                              />
                            </svg>
                          </v-btn>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  small
                                  icon
                                  color="black"
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <svg
                                    width="19"
                                    height="18"
                                    viewBox="0 0 19 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                      d="M10.5332 4.5C10.5332 5.04886 10.0821 5.5 9.5332 5.5C8.98435 5.5 8.5332 5.04886 8.5332 4.5C8.5332 3.95114 8.98435 3.5 9.5332 3.5C10.0821 3.5 10.5332 3.95114 10.5332 4.5ZM8.5332 9C8.5332 8.45114 8.98435 8 9.5332 8C10.0821 8 10.5332 8.45114 10.5332 9C10.5332 9.54886 10.0821 10 9.5332 10C8.98435 10 8.5332 9.54886 8.5332 9ZM8.5332 13.5C8.5332 12.9511 8.98435 12.5 9.5332 12.5C10.0821 12.5 10.5332 12.9511 10.5332 13.5C10.5332 14.0489 10.0821 14.5 9.5332 14.5C8.98435 14.5 8.5332 14.0489 8.5332 13.5Z"
                                      fill="#333333"
                                      stroke="#333333"
                                  />
                                </svg>
                              </v-btn>
                            </template>
                            <v-list dense>
                              <v-list-item

                                  @click="changeAOIName(item)"
                              >
                                <v-list-item-icon class="mr-2">
                                  <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                        d="M12.8946 2.73991C13.2083 2.42622 13.6337 2.25 14.0774 2.25C14.297 2.25 14.5145 2.29326 14.7175 2.37732C14.9204 2.46138 15.1048 2.58459 15.2601 2.73991C15.4154 2.89522 15.5386 3.07961 15.6227 3.28255C15.7067 3.48548 15.75 3.70299 15.75 3.92264C15.75 4.1423 15.7067 4.3598 15.6227 4.56273C15.5386 4.76567 15.4154 4.95006 15.2601 5.10538L5.40396 14.9615L2.25 15.75L3.03849 12.596L12.8946 2.73991Z"
                                        stroke="#006EC5"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M13.5 6.9375L11.0625 4.5M6.1875 14.25L3.75 11.8125"
                                        stroke="#006EC5"
                                        stroke-width="1.5"
                                    />
                                  </svg>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item

                                  @click="confirmDeleteAOI(item)"
                              >
                                <v-list-item-icon class="mr-2">
                                  <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                        d="M2.25 4.5H3.75H15.75"
                                        stroke="#EB5757"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5H14.25Z"
                                        stroke="#EB5757"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M7.5 8.25V12.75"
                                        stroke="#EB5757"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M10.5 8.25V12.75"
                                        stroke="#EB5757"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                  </svg>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-layout>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-layout>
                <div>
                  <v-layout class="px-3 py-2">
                    <v-spacer/>
                    <v-btn
                        width="36"
                        min-width="0"
                        height="36"
                        outlined
                        color="primary"
                        @click="() => $emit('deleteAll')"
                    >
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </v-layout>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Upload -->
          <v-tab-item value="upload_aoi" class="fill-height">
            <v-card flat class="fill-height">
              <v-card-text class="fill-height pa-0 d-flex flex-column">
                <div style="height: 45px; width: 100%; background-color: #F1F9FF; text-transform: uppercase">
                  <span style="font-size: 13px; color: #003F71; line-height: 45px" class="px-3">Upload AOI</span>
                </div>
                <div class="d-flex flex-column" style="width: 100%; height: 100%">
                  <v-layout class="fill-height">
                    <UploadForm ref="uploadForm" :multiple="true" @change="selectFile"
                                accept=".geojson, .json, .shp, .shx, .dbf, .prj, .cpg, .kml, .kmz"/>
                  </v-layout>
                  <div class="pa-3">
                    <v-layout>
                      <v-btn
                          dark
                          height="36"
                          color="primary"
                          class="flex mr-2"
                          @click="openSaveCurrentAOI"
                      >
                        Save Current AOI
                      </v-btn>
                      <v-btn
                          outlined
                          width="36"
                          min-width="0"
                          height="36"
                          color="primary"
                          class="flex-none"
                          @click="() => $emit('deleteAll')"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-layout>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Draw -->
          <v-tab-item value="draw" class="fill-height">
            <v-card flat class="fill-height">
              <v-card-text class="fill-height pa-0 d-flex flex-column">
                <v-overlay absolute :value="loading">
                  <v-progress-circular
                      indeterminate
                      size="64"
                  ></v-progress-circular>
                </v-overlay>
                <div
                    style="height: 45px; width: 100%; background-color: #F1F9FF; text-transform: uppercase"
                >
                <span
                    style="font-size: 13px; color: #003F71; line-height: 45px"
                    class="px-3"
                >Draw AOI</span
                >
                </div>
                <v-layout class="fill-height">
                  <v-list width="100%" flat>
                    <v-list-item-group :value="selectedTool" color="primary">
                      <v-list-item @click="() => $emit('changeMode', 'polygon')">
                        <v-list-item-icon class="mr-2">
                          <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              :fill="
                            selectedTool === 'polygon' ? '#006EC5' : '#333333'
                          "
                              xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                                d="M12 5L19.6 10.5L16.7 19.4H7.3L4.4 10.5L12 5ZM12 2.5L2 9.8L5.8 21.5H18.1L22 9.8L12 2.5Z"
                                :fill="
                              selectedTool === 'polygon' ? '#006EC5' : '#333333'
                            "
                            />
                          </svg>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                              v-bind:style="{
                            color: selectedTool === 'polygon' ? '#006EC5' : ''
                          }"
                          >Draw polygon (3)
                          </v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="() => $emit('changeMode', 'rectangle')">
                        <v-list-item-icon class="mr-2">
                          <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                                d="M3 3H21V21H3V3ZM5 5V19H19V5H5Z"
                                :fill="
                              selectedTool === 'rectangle'
                                ? '#006EC5'
                                : '#333333'
                            "
                            />
                          </svg>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                              v-bind:style="{
                            color: selectedTool === 'rectangle' ? '#006EC5' : ''
                          }"
                          >Draw rectangle (4))
                          </v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="() => $emit('changeMode', 'assist-rectangle')">
                        <v-list-item-icon class="mr-2">
                          <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                                d="M5 15H3V17H5V15ZM3 21H21V19H3V21ZM5 11H3V13H5V11ZM19 9H21V7H19V9ZM19 5H21V3H19V5ZM5 7H3V9H5V7ZM19 17H21V15H19V17ZM19 13H21V11H19V13ZM17 3H15V5H17V3ZM13 3H11V5H13V3ZM5 3H3V5H5V3ZM9 3H7V5H9"
                                :fill="
                              selectedTool === 'assist-rectangle'
                                ? '#006EC5'
                                : '#333333'
                            "
                            />
                          </svg>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                              v-bind:style="{
                            color:
                              selectedTool === 'assist-rectangle'
                                ? '#006EC5'
                                : ''
                          }"
                          >Draw assist rectangle (5)
                          </v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="() => $emit('changeMode', 'drag-circle')">
                        <v-list-item-icon class="mr-2">
                          <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                                d="M12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2Z"
                                :fill="
                              selectedTool === 'drag-circle'
                                ? '#006EC5'
                                : '#333333'
                            "
                            />
                          </svg>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                              v-bind:style="{
                            color:
                              selectedTool === 'drag-circle' ? '#006EC5' : ''
                          }"
                          >Draw circle (6)
                          </v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-layout>
                <div class="d-flex pa-3">
                  <v-btn
                      dark
                      height="36"
                      color="primary"
                      class="flex mr-2"
                      @click="openSaveCurrentAOI"
                  >
                    Save Current AOI
                  </v-btn>
                  <v-btn
                      outlined
                      width="36"
                      min-width="0"
                      height="36"
                      color="primary"
                      class="flex-none"
                      @click="() => $emit('deleteAll')"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-layout>
    <UnauthorizedDialog ref="unauthorized"/>
    <RenameDialog :action="updateAction" @fetchData="getData" ref="renameDialog"/>
    <DeleteDialog :action="deleteAOI" @fetchData="getData" ref="deleteAOI"/>
    <CreateDialog @fetchData="getData" ref="createAOI"/>
  </v-layout>
</template>

<script>
import {mapState} from "@/store/ults";
import {
  getCountries,
  getDistricts,
  getStateDetail,
  getStates,
  getSubDistricts,
  mergeDistricts,
  getStateDetails,
  mergeStates,
  fetchAOIs,
  updateAOI,
  deleteAOI
} from "@/backend";
import UnauthorizedDialog from "@/components/UnauthorizedDialog";
import UploadForm from "@/components/UploadForm";
import AOI from '@/ultis/AOI'
import RenameDialog from "@/components/RenameDialog";
import DeleteDialog from "@/components/DeleteDialog";
import sleep from "@/ultis/sleep";
import CreateDialog from "@/components/home/aoi/CreateDialog";

export default {
  name: "AOISideBar",
  components: {CreateDialog, DeleteDialog, RenameDialog, UploadForm, UnauthorizedDialog},
  data: () => ({
    deleteAOI: deleteAOI,
    updateAction: updateAOI,
    currentMethod: 'concavehull',
    selectedTool: undefined,
    selectedDistricts: [],
    country: undefined,
    province: [],
    district: undefined,
    subDistrict: undefined,
    provinces: [],
    districts: [],
    subDistricts: [],
    tab: 'search',
    loading: false,
    file: undefined,
    isUpload: false,
    listAOI: [],
    selectedAOI: undefined,
    countries: []
  }),
  created() {
    if (this.currentUser) this.getData()
  },
  mounted() {
    this.getAllCountries()
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
    ...mapState("aoi", ["currentAOI"])
  },
  methods: {
    async getData() {
      this.listAOI = []
      try {
        this.loading = true
        const res = await fetchAOIs()
        this.listAOI = res.data.data
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        this.loading = false
      }
    },
    async selectFile(files) {
      try {
        if (!this.currentUser) return (this.$refs.unauthorized.openDialog())
        if (!files.length) return
        let totalSize = 0
        files.forEach(file => {
          totalSize = totalSize + file.size
        })
        if (totalSize / 1024 / 1024 > 100) return (this.$store.commit('message/SHOW_ERROR', 'File size is too large | Max size: 100MB'))
        if (!AOI.check(files)) return this.$store.commit('message/SHOW_ERROR', 'Items invalid')
        await sleep(200)
        this.loading = true
        const res = await AOI.upload(AOI.transformData(files))
        const resSave = await AOI.saveAOI(
            files[0].name.split('.').shift(),
            res.data.data
        )
        await this.getData()
        this.$store.commit('message/SHOW_SUCCESS', resSave.data.message)
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        this.$refs.uploadForm.clear()
        this.loading = false
      }
    },
    selectAOI(aoi, index) {
      if (aoi.isView) {
        this.$emit('addGeojsonToMap', {
          data: [this.listAOI[index].geom],
          isZoom: true
        })
        let tmp = JSON.parse(JSON.stringify(this.listAOI))
        this.listAOI = []
        for (let i = 0; i < tmp.length; i++) {
          if (i !== index) {
            tmp[i].isView = false
          }
        }
        this.listAOI = tmp
      } else this.$emit('addGeojsonToMap', {data: [], isZoom: false})
    },
    downloadPolygon(item, index) {
      let text = JSON.stringify(item.geom)
      let data = new Blob([text], {type: 'application/json'})
      document.getElementById(
          `aoi_item_downloadButton_${index}`
      ).href = window.URL.createObjectURL(data)
    },
    changeAOIName(AOI) {
      this.$refs.renameDialog.openDialog(AOI.name, AOI.id)
    },
    confirmDeleteAOI(AOI) {
      this.$refs.deleteAOI.openDialog(AOI.name, AOI.id)
    },
    openSaveCurrentAOI() {
      if (!this.currentUser) this.$refs.unauthorized.openDialog()
      if (this.currentAOI) this.$refs.createAOI.openDialog()
      // else this.$emit('getGeom')
    },
    async getAllCountries() {
      const res = await getCountries()
      this.countries = res.data.data
    },
    async changeCountry(country) {
      if (country) {
        try {
          const res = await getStates(country.code)
          this.provinces = res.data.data
          this.province = []
          this.districts = []
          this.districts = []
          this.selectedDistricts = []
          this.subDistrict = undefined
          this.selectedDistricts = []
          this.$emit('addGeojsonToMap', {data: [], isZoom: false})
        } catch (e) {
          this.$store.commit('message/SHOW_ERROR', e.message)
        }
      } else {
        this.provinces = []
        this.districts = []
        this.subDistricts = []
        this.subDistrict = []
        this.province = []
        this.selectedDistricts = []
        this.$emit('addGeojsonToMap', {data: [], isZoom: false})
      }
    },
    async changeProvince(province) {
      let geometries = []
      if (province.length === 1) {
        try {
          const res = await getDistricts(province[0].code)
          this.districts = res.data.data
          this.subDistrict = undefined
          this.selectedDistricts = []
          this.subDistricts = []
          const resDetail = await getStateDetail(province[0].code)
          resDetail.data.data.forEach(data => {
            geometries.push(JSON.parse(data.geometry))
          })
          this.$emit('addGeojsonToMap', {data: geometries, isZoom: true})
        } catch (e) {
          this.$store.commit('message/SHOW_ERROR', e.message)
        }
      } else if (province.length > 1) {
        try {
          let ids = province.map(val => val.code)
          const res = await getStateDetails(ids)
          res.data.data.forEach(data => {
            geometries.push(JSON.parse(data.geometry))
          })
          this.changeMergeStates(this.currentMethod)
          // this.$emit('addGeojsonToMap', {data: geometries, isZoom: true})
        } catch (e) {
          this.$store.commit('message/SHOW_ERROR', e.message)
        }
      } else {
        this.districts = []
        this.selectedDistricts = []
        this.subDistricts = []
        this.subDistrict = undefined
        this.$emit('addGeojsonToMap', {data: [], isZoom: false})
      }
    },
    async changeDistrict(districts) {
      let geometries = []
      districts.forEach(data => {
        geometries.push(JSON.parse(data.geometry))
      })
      if (districts.length === 1) {
        try {
          const res = await getSubDistricts(districts[0].code)
          this.subDistricts = res.data.data
          this.$emit('addGeojsonToMap', {data: geometries, isZoom: false})
        } catch (e) {
          this.$store.commit('message/SHOW_ERROR', e.message)
        }
      } else if (!districts.length) {
        this.subDistricts = []
        this.subDistrict = undefined
        this.$emit('addGeojsonToMap', {data: [], isZoom: false})
      } else {
        this.changeMergeDistricts(this.currentMethod)
        this.$emit('addGeojsonToMap', {data: geometries, isZoom: false})
      }
    },
    async changeMergeDistricts(method) {
      this.currentMethod = method
      let geometries = []
      if (this.selectedDistricts && this.selectedDistricts.length > 1) {
        let districtIds = this.selectedDistricts.map(val => val.code)
        try {
          const res = await mergeDistricts({
            district_ids: districtIds,
            method: method
          })
          geometries.push(JSON.parse(res.data.data.geometry))
          this.$emit('addGeojsonToMap', {data: geometries, isZoom: false})
        } catch (e) {
          this.$store.commit('message/SHOW_ERROR', e.message)
        }
      }
    },
    async changeMergeStates(method) {
      let geometries = []
      if (this.province && this.province.length > 1) {
        let stateIds = this.province.map(val => val.code)
        try {
          const res = await mergeStates({
            state_ids: stateIds,
            method: method
          })
          geometries.push(JSON.parse(res.data.data.geometry))
          this.$emit('addGeojsonToMap', {data: geometries, isZoom: false})
        } catch (e) {
          this.$store.commit('message/SHOW_ERROR', e.message)
        }
      }
    },
    changeSubDistrict(subDistrict) {
      this.$emit('addGeojsonToMap', {
        data: [JSON.parse(subDistrict.geometry)],
        isZoom: false
      })
    },
  }
}
</script>

<style scoped>
.active-header {
  background-color: #F1F9FF;
}

.header {
  background-color: var(--v-tab-base)
}
</style>
