<template>
  <div>
    <v-card height="100%" flat>
      <v-data-table
        height="100%"
        style="height: 77%; border: 1px lightgrey solid"
        fixed-header
        hide-default-footer
        :headers="orderHeaders"
        :items="orders"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        :loading="loading"
        :items-per-page="15"
        loading-text="Loading... Please wait"
        class="row-pointer order-table"
        :server-items-length="totalItem"
        :options.sync="options"
        :page.sync="page"
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      >
        <template v-slot:no-data>
          <v-layout align-center justify-center class="py-5" column>
            <img
              :src="require('@/assets/images/orders-icon-26.jpg')"
              width="100"
            />
            <span style="font-size: 16px; color: #777c85" class="pl-4 pt-3"
              >No data order</span
            >
          </v-layout>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ new Date(item.created_at).toLocaleDateString("en-GB") }}
        </template>
        <template v-slot:[`item.image_count`]="{ item }">
          {{ item.images.length }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            style="width: 180px"
            small
            class="ma-2"
            :color="statuses.find((status) => status.id == item.status).color"
            text-color="white"
          >
            <v-layout
              justify-center
              align-center
              class="fill-height"
              style="width: 100%"
            >
              {{ statuses.find((status) => status.id == item.status).value }}
            </v-layout>
          </v-chip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <v-data-table
              class="elevation-0 list-image"
              :headers="orderItemHeaders"
              :items="item.images"
              item-key="id"
              hide-default-footer
              disable-filtering
              disable-pagination
              disable-sort
            >
              <template v-slot:[`item.action`]="{ item }">
                <!--                <v-btn-->
                <!--                    icon-->
                <!--                    color="primary"-->
                <!--                    title="Preview"-->
                <!--                    @click="showPreview(item)"-->
                <!--                >-->
                <!--                  <v-icon>mdi-eye-outline</v-icon>-->
                <!--                </v-btn>-->
                <v-btn
                  :disabled="!item.link || item.status != 6"
                  icon
                  color="success"
                  title="Download"
                  @click="download(item.id)"
                >
                  <v-icon>mdi-download-outline</v-icon>
                </v-btn>

                <v-btn
                  :disabled="!item.link || item.status != 6"
                  icon
                  color="primary"
                  title="Import to Eofactory"
                  @click="importToEOF(item)"
                >
                  <v-icon>mdi-import</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          class="ma-3 mt-6"
          :total-visible="5"
          v-model="page"
          :length="pageCount"
          id="task_pagination"
        ></v-pagination>
      </div>
    </v-card>
    <WorkspaceDialog v-if="currentUser" ref="workspaceDialog" />
  </div>
</template>

<script>
import { mapState } from "@/store/ults";
import { getOrders, downloadOrderImage, getThumbnail } from "@/backend";
import WorkspaceDialog from "@/components/order/WorkspaceDialog";
export default {
  name: "OtherSourcesOrderList",
  components: {
    WorkspaceDialog,
  },
  data() {
    return {
      options: {},
      page: 1,
      totalItem: 0,
      pageCount: 0,
      expanded: [],
      singleExpand: true,
      loaded: false,
      loading: false,
      orderHeaders: [
        {
          text: "Order ID",
          align: "center",
          sortable: false,
          value: "id",
        },
        { text: "Date", value: "created_at", align: "center" },
        { text: "Image Count", value: "image_count", align: "center" },
        { text: "Status", value: "status", align: "center" },
      ],
      orderItemHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Date", value: "date", align: "center" },
        { text: "Type", value: "type", align: "center" },
        { text: "Dataset", value: "dataset", align: "center" },
        { text: "Cloud", value: "cloud_cover", align: "center" },
        { text: "Resolution", value: "resolution", align: "center", width: 20 },
        { text: "Action", value: "action", align: "center" },
      ],
      orders: [],
      statuses: [
        {
          id: 1,
          value: "Request quotation submitted",
          color: "#00a6de",
        },
        {
          id: 2,
          value: "Quotation email to client",
          color: "#36b4af",
        },
        {
          id: 3,
          value: "Pending purchase confirmation",
          color: "#04bb6b",
        },
        {
          id: 4,
          value: "Issue invoice to customer",
          color: "#00d8e2",
        },
        {
          id: 5,
          value: "Pending delivery",
          color: "#04bb6b",
        },
        {
          id: 6,
          value: "Order delivery",
          color: "#25db00",
        },
        {
          id: 7,
          value: "Canceled",
          color: "#d6dc01",
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.currentUser) this.getData();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const { data } = await getOrders({
          per_page: this.options.itemsPerPage,
          page: this.options.page,
        });
        this.totalItem = data.meta.total;
        this.pageCount = data.meta.last_page;
        this.orders = JSON.parse(JSON.stringify(data.data));
        this.orders.map((order) =>
          order.images.map((image) => (image.status = order.status))
        );
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    async showPreview(image) {
      try {
        this.loading = true;
        const { url } = await getThumbnail({
          dataset: image.dataset,
          thumbnail: image.thumbnail,
        });
        this.image = {
          url: (url.startsWith("http") ? "" : env.ig_search) + url,
          geometry: image.geometry,
          dataset: image.dataset,
        };
        this.dialog = true;
      } catch (error) {
        this.$store.commit("message/SHOW_ERROR", "An error has occurred");
      } finally {
        this.loading = false;
      }
    },
    importToEOF(image) {
      this.$refs.workspaceDialog.openWorkspaceDialog(image);
    },
    async download(id) {
      try {
        this.loading = true;
        const response = await downloadOrderImage(id);
        window.open(response.data.url, "_blank");
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
