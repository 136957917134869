<template>
  <v-autocomplete
    v-model="selectedResult"
    v-bind="$attrs"
    :items="entries"
    :loading="isLoading"
    :search-input.sync="search"
    :hide-no-data="!search && !isLoading && !!entries"
    filled
    dense
    append-icon="mdi-magnify"
    class="geocoder-container"
    hide-selected
    hide-details
    item-text="full_name"
    return-object
    @change="onSelectResult"
  />
</template>

<script>

import {geocoder} from "@/backend";

function debounce (func, wait, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export default {
  data () {
    return {
      activated: false,
      selectedResult: null,

      search: null,
      entries: [],
      isLoading: false,

      cancelledGeocoding: false
    }
  },

  watch: {
    search (query) {
      if (query) this.geocode(query)
      else this.cancelGeocoding()
    }
  },

  methods: {
    geocode: debounce(async function (query) {
      if (this.cancelledGeocoding) {
        this.cancelledGeocoding = false
        return
      }

      try {
        this.isLoading = true

        const res = await geocoder(query)

        if (this.cancelledGeocoding) {
          this.cancelledGeocoding = false
          this.isLoading = false
          return
        }

        this.entries = res.data.data
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    }, 500),

    onSelectResult () {
      this.$emit('change', this.selectedResult)

      this.search = ''
      this.selectedResult = undefined
      this.entries = []
    },

    cancelGeocoding () {
      this.cancelledGeocoding = true
      this.entries = []
    }
  }
}
</script>

<style>
.geocoder-container .notranslate {
  transform: none !important;
}
</style>
