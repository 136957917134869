<template>
  <v-dialog v-model="isShowAmazonS3Dialog" width="400" persistent>
    <v-card>
      <v-card-title class="popup-header">
        <span>Amazon S3 Config</span>
        <v-spacer />
        <v-btn
          icon
          small
          dark
          @click="
            () => {
              isShowAmazonS3Dialog = false;
              $emit('uncheckAmazonS3Config');
            }
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-5">
        <div class="fill-height">
          <v-form ref="formData" v-model="valid">
            <v-text-field
              v-model="amazonS3Config.bucket"
              :rules="[rules.required]"
              label="Bucket"
              required
            ></v-text-field>
            <v-text-field
              v-model="amazonS3Config.region"
              :rules="[rules.required]"
              label="Region"
              required
            ></v-text-field>
            <v-text-field
              v-model="amazonS3Config.accessKey"
              :rules="[rules.required]"
              label="Access Key"
              required
            ></v-text-field>
            <v-text-field
              v-model="amazonS3Config.secretKey"
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="show3 ? 'text' : 'password'"
              name="input-10-2"
              label="Secret Key"
              class="input-group--focused"
              @click:append="show3 = !show3"
            ></v-text-field>
            <v-text-field
              v-model="amazonS3Config.bucketPathPrefix"
              label="Bucket Path Prefix"
            ></v-text-field>
          </v-form>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" color="primary" text @click="saveConfig">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AmazonS3ConfigDialog",
  data() {
    return {
      isShowAmazonS3Dialog: false,
      loading: false,
      rules: {
        required: (value) => !!value || "Field is required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      show3: false,
      valid: false,
      amazonS3Config: {
        bucket: "",
        region: "",
        accessKey: "",
        secretKey: "",
        bucketPathPrefix: "",
      },
    };
  },
  methods: {
    openDialog() {
      this.isShowAmazonS3Dialog = true;
      if (this.$refs.formData) this.$refs.formData.resetValidation()
    },
    saveConfig() {
      this.$refs.formData.validate();
      if (!this.valid) return;
      this.isShowAmazonS3Dialog = false;
    },
  },
};
</script>
