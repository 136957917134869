<template>
  <div
    class="d-flex fill-height justify-center flex-column px-8"
    style="width: 100%"
  >
    <div
      style="width: 100%; height: 155px; border-bottom: 1px solid lightgrey"
      class="d-flex align-center justify-center flex-column"
    >
      <h1>
        <v-icon color="#333333" size="40" class="mr-1">mdi-cart</v-icon>Orders
      </h1>
      <p>View your active and past data orders.</p>
    </div>
    <v-container
      fluid
      class="flex-fill"
      style="height: calc(100vh - 220px); max-width: 1400px"
    >
      <v-select
        :items="sourceTypes"
        v-model="orderSource"
        label="Select source"
        @change="getData"
      ></v-select>

      <other-sources-order-list v-if="orderSource === 'other_sources'" ref="otherSourcesOrderRef"></other-sources-order-list>
      <latconnect-60-order-list v-else ref="latconnect60OrderRef"></latconnect-60-order-list>
    </v-container>
  </div>
</template>

<script>
import OtherSourcesOrderList from './OtherSourcesOrderList.vue';
import Latconnect60OrderList from './Latconnect60OrderList.vue';
export default {
  components: {
    OtherSourcesOrderList,
    Latconnect60OrderList,
  },
  data() {
    return {
      orderSource: 'other_sources',
      sourceTypes: [
        {
          text: "Other Sources",
          value: "other_sources",
        },
        {
          text: "Latconnect60",
          value: "latconnect60_source",
        },
      ],
    };
  },
 
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.orderSource === 'other_sources') {
        this.$refs.otherSourcesOrderRef?.getData()
      } else {
        this.$refs.latconnect60OrderRef?.getData()
      }
    },
  },
};
</script>
<style scoped>
/deep/ .v-chip__content {
  width: 180px !important;
}
/deep/
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: inset 0px 4px 6px -5px rgb(50 50 50 / 75%),
    inset 0px -4px 8px -5px rgb(50 50 50 / 0%);
}
/deep/ .order-table th {
  background-color: #435b6a !important;
  color: white !important;
}
/deep/ .list-image th {
  background-color: #6686a3 !important;
}
</style>
