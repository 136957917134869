<template>
  <div style="display: inline-flex;flex-direction: column;">
    <v-menu
        open-on-click
        transition="slide-x-transition"
        offset-x
        bottom
        left
    >
      <template v-slot:activator="{ on }">
        <v-btn color="button" v-on="on" title="Draw" width="45" min-width="0" height="45">
          <v-layout column>
            <v-icon>mdi-selection-drag</v-icon>
            <div style="font-size: 10px">Draw</div>
          </v-layout>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="draw('polygon')">
          <v-list-item-title>
            <v-icon>mdi-vector-polygon</v-icon> Draw polygon (3)
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="draw('rectangle')">
          <v-list-item-title>
            <v-icon>mdi-rectangle-outline</v-icon> Draw rectangle (4)
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="draw('assist-rectangle')">
          <v-list-item-title>
            <v-icon>mdi-vector-rectangle</v-icon> Draw assist rectangle (5)
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="draw('drag-circle')">
          <v-list-item-title>
            <v-icon>mdi-vector-circle</v-icon> Draw circle (6)
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DrawTool",
  data () {
    return {
    }
  },
  methods: {
    draw (mode) {
      this.$emit('changeMode', mode)
    }
  }
}
</script>

<style scoped>

</style>
